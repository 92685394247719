import React from 'react';
import close from 'assets/img/delete.png';
import deleteIcon from 'assets/img/delete.png';
import Form from 'components/Dropdowns/Form';
import Register from './Register';

const Login = ({ closePopup }) => {
  return (
    <div className="popup-container fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-1000">
      <div  className="popup-content bg-white xl:w-4/12 lg:w-6/12  md:w-8/12 w-12/12   rounded-lg shadow-lg relative z-60  xl:h-0 lg:h-0 md:h-0">
        <img
          src={deleteIcon}
          alt="Close"
          className="w-6 absolute top-0 right-0 cursor-pointer mt-4 mr-4"
          onClick={closePopup}
        />
        <div>
          {/* Pass closePopup as a prop to Register */}
          <Register closePopup={closePopup} />
        </div>
      </div>
    </div>
  );
};

export default Login;
