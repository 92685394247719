/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import SliderPage from "./SliderPage";
import AboutUs from "components/Pages/about/AboutUs";
import Aboutt from "about/aboutpage/Aboutt";
import Homepage1 from "components/Homepage/Homepage1";

export default function Index() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <Homepage1/>
    </section>
  );
}
