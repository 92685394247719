import React, { useState, useRef, useEffect } from "react";
import Modal from 'react-modal';
import close from 'assets/img/close.webp';
import deleteicon from 'assets/img/delete.png';

Modal.setAppElement('#root'); // This is important for accessibility

function CancellationPolicy({ trigger, setTrigger, children }) {
 

  return (
    <Modal
      isOpen={trigger}
      onRequestClose={() => setTrigger(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
          position: 'fixed',
          inset: 0,
          zIndex: 999, // Ensure the overlay is above other content
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Center the modal vertically
        },
        content: {
          position: 'relative',
          inset: 'unset', // Remove default inset
          padding: '2px',
          background: 'white',
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          width: '90%',
          maxWidth: '500px', // Default max width
          margin: 'auto', // Center horizontally
        },
      }}
      className="max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-5xl"
    >
      <button
        className=" absolute top-4 right-0 mr-4 mt-4"
        onClick={() => setTrigger(false)}
        aria-label="Close" style={{ outline: 'none' }}
      >
        <img src={deleteicon} alt="Close" className="w-6 h-6" />
      </button>
      {children}
    </Modal>
  );
}

export default CancellationPolicy;
