import React, { useState } from "react";
import nurturevideo4 from "assets/img/nurturevideo4.mp4";
import nurturedoc1 from "assets/img/nurturedoc1.png";
import nurturedoc2 from "assets/img/nurturedoc2.png";
import nurturedoc3 from "assets/img/nurturedoc3.png";
import nurturedoc4 from "assets/img/nurturedoc4.png";
import Login from "views/auth/Login";

const VideoBackgroundComponent = () => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="video-container relative xl:h-screen xl:-mt-32 lg:-mt-30 md:h-540 h-500-px">
      {/* Full height video background */}
      <video
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1, // Make sure video stays behind
        }}
      >
        <source src={nurturevideo4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0, // Background overlay behind content
        }}
      ></div>

      <div className="flex items-center justify-start h-full relative z-10 xl:pt-16 lg:pt-8 md:pt-8 pt-8 class">
        <div className="xl:px-2 container mx-auto lg:px-6 md:px-4 px-4">
          <h1 className="xl:text-6xl text-iceblue1 lg:text-4xlll md:text-4xl text-3xl figtree">
            Admire the baby <br />
            <span className="xl:text-6xl font-bold text-sandybrown1 lg:text-4xlll md:text-4xl text-3xl">
              and nurture the mother
            </span>
          </h1>

          <div>
            <h1 className="xl:text-base1 text-blackk xl:mt-5 lg:text-xl lg:mt-4 md:text-xl text-xl md:mt-4 mt-4">
              Meet <span className="font-bold">our doctors</span>
            </h1>

            <div className="flex">
              <div className="flex xl:mt-2 lg:mt-2 mt-2">
                <div className="border-5 rounded-full">
                  <img className="w-12 rounded-full" src={nurturedoc2} alt="" />
                </div>
                <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                  <div className="border-5 rounded-full">
                    <img
                      className="w-12 rounded-full"
                      src={nurturedoc4}
                      alt=""
                    />
                  </div>
                </div>
                <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                  <div className="border-5 rounded-full">
                    <img
                      className="w-12 rounded-full"
                      src={nurturedoc3}
                      alt=""
                    />
                  </div>
                </div>
                <div className="xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                  <div className="border-5 rounded-full">
                    <img
                      className="w-12 rounded-full"
                      src={nurturedoc1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="xl:-ml-4 z-10 mt-1 lg:-ml-4 -ml-4">
                  <div className="bg-newshade16 h-12 w-12 rounded-full border-5">
                    <a href="/ourteam">
                      <span className="text-white xl:text-2xl lg:text-2xl text-2xl pl-3 pt-2 text-center">
                        +
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="xl:ml-4 lg:ml-4 md:ml-4 ml-1 xl:mt-3 lg:mt-4 md:mt-4 sofia-pro xl:block lg:block md:block sm:hidden hidden">
                <button
                  className="text-snowhite xl:text-base cursor-pointer xl:px-4 xl:py-3 rounded-md bg-gradient xl:px-4 md:px-4 px-2 py-2"
                  style={{ outline: "none" }}
                  onClick={openPopup}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
            {/* Mobile view button */}
            <div className="mt-4 sofia-pro xl:hidden lg:hidden md:hidden sm:block block">
              <button
                className="text-snowhite cursor-pointer rounded-md bg-gradient text-base px-2 py-2"
                style={{ outline: "none" }}
                onClick={openPopup}
              >
                Book an Appointment
              </button>
            </div>
          </div>

          {/* Popup modal */}
          <div className=""style={{  zIndex: 999 }}>
            {showPopup && <Login closePopup={closePopup} />}</div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackgroundComponent;
