import Footer2 from "components/Footers/Footer2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import nurturebannerr from "assets/img/nurturebannerr.png";

import GallerySlider1 from "views/GallerySlider1";

export default function Gallery() {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
    return (
        <section>
        <Indexnavbar2 />
        <section className="xl:block lg:block md:block sm:hidden hidden figtree">
        <div>
          <div className="xl:h-360 lg:h-380 md:h-380 h-350-px"
            style={{
              backgroundImage: `url(${nurturebannerr})`,
              width: "100%",
              
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block figtree -mt-8">
        <div>
          <div className="-mb-28"
            style={{
              backgroundImage: `url(${nurturebannerr})`,
              width: "100%", // Full width of the container
              height: "400px", // Fixed height for larger screens
              backgroundSize: "contain", // Covers the container while maintaining aspect ratio
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

       <div>
        <div>
          <GallerySlider1/>
        </div>
       </div>


        <Footer2 />
        </section>
    );
}