import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import patternbg from "assets/img/patternbg.svg";
import servicesbanner from "assets/img/servicesbanner.png";

export default function Services() {
  const location = useLocation();
  const [activeToggle, setActiveToggle] = useState(null);

  // Initialize local state with location state on component mount
  useEffect(() => {
    if (location.state && location.state.activeToggle !== undefined) {
      setActiveToggle(location.state.activeToggle);
    }
  }, [location.state]);

  const handleToggle = (index) => {
    setActiveToggle((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <>
      <Indexnavbar2 fixed />
      <section className="xl:block lg:block md:block sm:hidden hidden figtree">
        <div>
          <div className="xl:h-360 lg:h-380 md:h-380 h-350-px"
            style={{
              backgroundImage: `url(${servicesbanner})`,
              width: "100%",
              
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block figtree -mt-8">
        <div>
          <div className="-mb-28"
            style={{
              backgroundImage: `url(${servicesbanner})`,
              width: "100%", // Full width of the container
              height: "400px", // Fixed height for larger screens
              backgroundSize: "contain", // Covers the container while maintaining aspect ratio
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      {/*final layout*/}
      <section className="xl:block lg:block md:block sm:hidden hidden bg-newshade12 xl:pb-16 lg:pb-16 pb-12 sofia-pro">
        <div className="container mx-auto xl:px-4 lg:px-4 px-4">
          <div className="flex flex-wrap xl:pt-16 lg:pt-12 pt-12">
            {/*section 1st div*/}
            <div className="">
              <div
                className="width1 sticky xl:top-16  xl:pb-16  border-rightt"
                style={{
                  backgroundSize: "auto", // or "contain" depending on the desired effect
                  backgroundPosition: "top right",
                  backgroundRepeat: "no-repeat",
                  height: "calc(100vh - 2rem)", // Adjust the height to your needs
                  overflowY: "scroll", // Enables vertical scrolling
                  // Remove or adjust these properties to make the scrollbar visible
                  scrollbarWidth: "auto", // For Firefox (auto shows the default scrollbar)
                  msOverflowStyle: "auto", // For Internet Explorer and Edge (auto shows the default scrollbar)
                }}
              >
                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer pr-4"
                    onClick={() => handleToggle(0)}
                  >
                    <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                      Fertility
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(1)}
                  >
                    <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                      Paediatrics
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(2)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Dermatology
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 2 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(3)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Postnatal Care
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 3 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(12)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Laparoscopic Surgery
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 12 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(13)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Ultrasonography
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 13 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(14)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue">
                        Physiotherapy
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 14 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(15)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Neonatology
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 15 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(16)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Gynecological Oncology
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 16 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(17)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        ANC Care
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 17 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(18)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        High Risk Pregnancy
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 18 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(19)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Obstetrics
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 19 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(20)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Fetal Medicine
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(21)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Family planning
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 21 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(22)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Adolescent Health
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 22 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(23)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Well woman clinic
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 23 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(24)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Vaccination
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 24 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(25)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        NICU
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 25 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(26)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Cosmetology
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 26 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(27)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        General Surgery
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 27 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>

                  <div className="">
                    <div
                      className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                      onClick={() => handleToggle(28)}
                    >
                      <h1 className="xl:text-lg font-semibold text-iceblue lg:text-lg text-base">
                        Urology and Urogynaecology
                      </h1>
                      <p className="text-xl font-bold text-iceblue">
                        {activeToggle === 28 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 15l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </div>

                  <hr className="border-blue-50 mt-4"></hr>
                </div>
              </div>
            </div>

            {/*section 2nd div*/}
            <div
              className="bg-white xl:pt-8 xl:p-6 xl:ml-12 xl:pb-8 lg:ml-4 lg:p-6 lg:pt-8 lg:pb-8 p-6 pt-8 md:ml-2 md:pb-0 pb-0"
              style={{
                width: "70%",
                backgroundColor: "ffffff",
                backgroundSize: "auto", // or "contain" depending on the desired effect
                backgroundPosition: "top right",
                backgroundRepeat: "no-repeat",
                height: "calc(100vh - 2rem)", // Adjust the height to your needs
                overflowY: "scroll", // Enables vertical scrolling
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none", // For Internet Explorer and Edge
              }}
            >
              <div className="sofia-pro ml-2">
                {activeToggle === 0 && (
                  <div>
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Fertility
                    </h1>

                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      we are dedicated to helping individuals and couples
                      realize their dreams of parenthood. As a leading fertility
                      hospital, we provide personalized, compassionate, and
                      advanced fertility treatments, supported by a team of
                      expert reproductive specialists. Whether you are just
                      beginning your fertility journey or seeking advanced
                      reproductive technologies, we are here to support you
                      every step of the way.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      {" "}
                      World-Class Fertility Experts
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of experienced fertility specialists,
                      embryologists, and counselors are pioneers in reproductive
                      medicine, offering cutting-edge treatments tailored to
                      your unique needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      {" "}
                      Advanced Reproductive Technology
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We employ the latest technologies, including IVF, ICSI,
                      genetic testing, and cryopreservation, ensuring the
                      highest success rates and the best possible care.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Treatment Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Every fertility journey is unique. We create customized
                      treatment plans that cater to your specific medical,
                      emotional, and lifestyle needs, giving you the best chance
                      at success.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From fertility assessments to fertility preservation, we
                      offer a full spectrum of services designed to support you
                      on your path to parenthood. Our holistic approach also
                      includes emotional support and wellness services.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="contact?/">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 1 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Paediatrics
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2  lg:text-lg text-base">
                      We understand that children are special, and so are their
                      healthcare needs. Our dedicated team of pediatric
                      specialists, nurses, and healthcare professionals are
                      committed to providing the highest quality of medical care
                      in a comforting, child-friendly environment. We focus not
                      only on treating illnesses but also on the holistic
                      development and well-being of every child.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Pediatric Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From newborns to adolescents, we provide a full spectrum
                      of pediatric services, ensuring your child receives
                      age-appropriate and specialized care.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Experienced Pediatric Team
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our pediatricians, surgeons, and specialists are highly
                      experienced in handling a wide range of conditions. We
                      focus on preventative care, early diagnosis, and advanced
                      treatments.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Child-Friendly Environment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our hospital is designed with children in mind – with
                      colorful play areas, comfortable patient rooms, and staff
                      trained to reduce anxiety in young patients.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      24/7 Pediatric Emergency Services
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Medical emergencies can happen at any time. Our emergency
                      department is open 24/7, staffed with pediatric
                      specialists trained to handle everything from injuries to
                      severe illnesses.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 2 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Dermatology
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  lg:text-lg text-base pt-2">
                      We specialize in providing comprehensive dermatological
                      care for all age groups, offering advanced treatments for
                      skin, hair, and nail conditions. Whether you need help
                      managing chronic skin conditions, cosmetic treatments, or
                      minor skin surgeries, our team of expert dermatologists is
                      here to ensure your skin remains healthy and radiant.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Dermatologists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of board-certified dermatologists is trained in
                      both medical and cosmetic dermatology, ensuring accurate
                      diagnosis and effective treatment plans tailored to your
                      unique needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Cutting-Edge Technology
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest advancements in dermatological
                      technology, including laser treatments, phototherapy, and
                      minimally invasive cosmetic procedures, to deliver the
                      best possible outcomes.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Skin Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From acne management to skin cancer screenings, we offer a
                      full range of dermatology services to address both routine
                      and complex skin concerns.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We believe in providing personalized care in a
                      comfortable, compassionate environment, ensuring our
                      patients feel supported throughout their treatment
                      journey.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 3 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Postnatal Care
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2 pt-2  lg:text-lg text-base">
                      we understand that the postnatal period is a crucial time
                      for both mother and baby. Our expert team is dedicated to
                      ensuring a smooth and healthy transition from pregnancy to
                      parenthood by providing comprehensive care that promotes
                      the well-being of both the mother and the newborn. Our
                      goal is to support, educate, and empower you during this
                      beautiful journey.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Experienced Medical Team
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of obstetricians, neonatologists, pediatricians,
                      and nurses specializes in postnatal care to ensure the
                      best possible support and treatment for both mother and
                      baby.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Holistic Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer a range of services addressing the physical,
                      emotional, and psychological needs of new mothers, with a
                      strong focus on recovery, breastfeeding support, and baby
                      care education.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Family-Centered Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We believe in involving the whole family in postnatal
                      care, offering education and support for fathers,
                      partners, and extended family members to ensure a
                      nurturing and supportive environment for both mother and
                      baby.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comfortable Environment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our postnatal wards are designed to offer a calm,
                      nurturing, and private environment to help new mothers
                      recover and bond with their newborns. Rooming-in
                      facilities are available for families.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 12 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue font-bold lg:text-2xl text-2xl">
                      Laparoscopic Surgery
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      we are committed to offering the latest in minimally
                      invasive surgical techniques, ensuring quicker recovery
                      times, less pain, and minimal scarring. Laparoscopic
                      surgery, also known as keyhole surgery, is a revolutionary
                      method used for various surgical procedures. With our team
                      of highly skilled surgeons and cutting-edge technology, we
                      provide safe, effective solutions for a wide range of
                      medical conditions.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Surgical Team
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our surgeons are highly trained in laparoscopic
                      procedures, offering years of experience in handling
                      complex surgeries with precision and care.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest laparoscopic equipment and
                      technology, ensuring the highest standards of care and
                      safety for our patients.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Minimally Invasive Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Laparoscopic surgery involves smaller incisions, which
                      result in reduced pain, quicker recovery, and minimal
                      scarring compared to traditional open surgery.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We focus on personalized care, from diagnosis and surgical
                      planning to post-operative recovery, ensuring that each
                      patient receives comprehensive and compassionate
                      treatment.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 13 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Ultrasonography
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2 pt-2 lg:text-lg text-base">
                      we are committed to offering the latest in diagnostic
                      imaging technology through our comprehensive
                      ultrasonography services. Ultrasonography, also known as
                      ultrasound, is a non-invasive imaging technique used to
                      visualize internal organs and structures, helping in the
                      diagnosis and monitoring of various medical conditions.
                      Our expert radiologists and sonographers ensure accurate
                      and detailed imaging, supporting the best clinical
                      outcomes for our patients.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Ultrasound Technology
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our hospital is equipped with state-of-the-art ultrasound
                      machines that provide high-resolution imaging for detailed
                      and accurate diagnosis.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Experienced Radiologists and Sonographers
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of highly trained radiologists and certified
                      sonographers specializes in performing and interpreting
                      ultrasound scans, ensuring precise and reliable results.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Non-Invasive and Safe
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Ultrasound is a safe and painless imaging technique that
                      does not use radiation, making it an ideal diagnostic tool
                      for a wide range of conditions, including pregnancy
                      monitoring and organ assessment.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Imaging Services
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer a broad spectrum of ultrasound services, from
                      routine pregnancy scans to specialized imaging for various
                      medical conditions, ensuring a holistic approach to
                      diagnostics.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 14 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Physiotherapy
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 text-base lg:text-lg">
                      we are dedicated to providing top-tier physiotherapy
                      services that help patients restore movement, reduce pain,
                      and improve overall physical function. Our team of highly
                      trained physiotherapists specializes in designing
                      individualized treatment plans that cater to your specific
                      needs, whether you are recovering from surgery, dealing
                      with a sports injury, or managing a chronic condition.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Physiotherapists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team consists of certified and experienced
                      physiotherapists who are committed to helping you achieve
                      optimal physical health and wellness.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest equipment and rehabilitation
                      technologies to provide advanced treatment options that
                      enhance recovery and maximize results.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Treatment Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Every patient receives a tailored physiotherapy plan that
                      addresses their unique medical condition, physical goals,
                      and lifestyle needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Holistic Approach to Healing
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We focus on a holistic approach that integrates manual
                      therapy, exercise, and patient education, ensuring
                      long-term recovery and health maintenance.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 15 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl ">
                      Neonatology
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2 pt-2 lg:text-lg text-base">
                      We specialize in providing advanced medical care for
                      newborns, particularly premature or critically ill
                      infants, through our state-of-the-art Neonatology
                      Department. Our dedicated team of neonatologists, nurses,
                      and specialists are committed to offering round-the-clock
                      care, ensuring that every newborn receives the specialized
                      attention they need for a healthy start in life.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Neonatologists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of highly skilled neonatologists has extensive
                      experience in managing complex conditions in newborns,
                      ensuring the highest standard of care.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Neonatal Intensive Care Unit (NICU)
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our hospital is equipped with a modern NICU, offering the
                      most advanced technology for monitoring, diagnosing, and
                      treating critically ill and premature newborns.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Compassionate, Family-Centered Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize family involvement in neonatal care,
                      providing emotional support and education to help parents
                      navigate their newborn’s medical journey with confidence.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Team Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our care extends beyond neonatologists, including
                      pediatric surgeons, cardiologists, neurologists, and
                      nutritionists, who collaborate to offer comprehensive care
                      for every baby.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 16 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Gynecological Oncology
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      We offer specialized care for women facing gynecological
                      cancers through our dedicated Gynecological Oncology
                      Department. Our mission is to provide comprehensive,
                      personalized treatment for cancers affecting the female
                      reproductive system, including the ovaries, uterus,
                      cervix, vulva, and vagina. With a team of leading
                      specialists, state-of-the-art facilities, and a
                      patient-centered approach, we are committed to delivering
                      the highest standard of care and support.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Leading Oncologists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team includes renowned gynecological oncologists with
                      extensive experience in diagnosing and treating
                      gynecological cancers, ensuring expert care and advanced
                      treatment options.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Treatment Technologies
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest technologies and treatment
                      modalities, including minimally invasive surgical
                      techniques, targeted therapies, and cutting-edge radiation
                      therapies.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Care Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We develop individualized treatment plans tailored to each
                      patient’s specific needs, ensuring a comprehensive
                      approach that addresses their unique medical condition and
                      personal preferences.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Team Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our care involves a collaborative team of oncologists,
                      surgeons, radiologists, pathologists, and support staff,
                      working together to provide holistic care and achieve the
                      best outcomes.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 17 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      ANC Care
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2 pt-2 lg:text-lg text-base">
                      We are dedicated to providing exceptional antenatal care
                      to support a healthy pregnancy and ensure the well-being
                      of both mother and baby. Our Antenatal Care (ANC) services
                      encompass a wide range of medical, educational, and
                      emotional support tailored to meet the unique needs of
                      each expecting mother. From early pregnancy through labor
                      and delivery, our team is here to guide and support you
                      every step of the way.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Obstetricians
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of experienced obstetricians provides expert
                      care, closely monitoring your health and your baby’s
                      development throughout your pregnancy.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Care Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We develop individualized antenatal care plans based on
                      your medical history, health needs, and personal
                      preferences to ensure the best outcomes for you and your
                      baby.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our hospital is equipped with advanced diagnostic and
                      monitoring technologies to provide comprehensive and
                      accurate assessments of your pregnancy
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Supportive and Caring Team
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From initial consultations to delivery, our dedicated
                      nurses, midwives, and support staff offer compassionate
                      care and support to make your pregnancy journey as smooth
                      as possible.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 18 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      High Risk Pregnancy
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      We understand that a high-risk pregnancy requires
                      specialized care and attention to ensure the health and
                      safety of both mother and baby. Our High-Risk Pregnancy
                      Department is dedicated to providing comprehensive, expert
                      care for pregnancies that present additional challenges.
                      With a team of experienced specialists, advanced
                      diagnostic tools, and personalized treatment plans, we are
                      committed to supporting you through every stage of your
                      high-risk pregnancy journey.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Specialized Obstetricians
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of high-risk obstetricians has extensive
                      experience in managing complex pregnancies, offering
                      expert care and advanced treatment options tailored to
                      your needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Diagnostic and Monitoring Technologies
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We use state-of-the-art technologies, including advanced
                      imaging and genetic testing, to monitor your pregnancy and
                      identify any potential issues early.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Team Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our approach involves a collaborative team of specialists,
                      including maternal-fetal medicine experts, neonatologists,
                      and other healthcare professionals, to provide holistic
                      care and achieve the best outcomes.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Care Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We develop individualized care plans that address your
                      specific health concerns and pregnancy challenges,
                      ensuring a tailored approach to manage risks and support a
                      healthy pregnancy.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 19 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Obstetrics
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      we offer exceptional obstetric care to support you through
                      the entire journey of pregnancy, from conception to
                      postpartum. Our Obstetrics Department is dedicated to
                      providing comprehensive, personalized care to ensure the
                      health and well-being of both you and your baby. With a
                      team of skilled obstetricians, advanced technology, and a
                      compassionate approach, we are here to guide you through
                      every step of your pregnancy experience.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Experienced Obstetricians
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of experienced obstetricians provides expert care
                      and support throughout your pregnancy, ensuring the best
                      outcomes for both mother and baby.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Care Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We create individualized care plans based on your health
                      needs, pregnancy progression, and personal preferences to
                      provide tailored support and guidance.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our hospital is equipped with the latest technology and
                      facilities to offer advanced diagnostic, monitoring, and
                      treatment options for all aspects of obstetric care.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Supportive and Caring Environment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize a compassionate and supportive environment,
                      focusing on your comfort and well-being throughout your
                      pregnancy journey.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 20 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Fetal Medicine
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      we provide specialized care in fetal medicine to ensure
                      the health and well-being of your baby throughout
                      pregnancy. Our Fetal Medicine Department is dedicated to
                      offering comprehensive, cutting-edge care for the
                      management and monitoring of fetal conditions and
                      complications. With a team of expert fetal medicine
                      specialists and advanced diagnostic technologies, we are
                      here to support you and your baby every step of the way.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Fetal Medicine Specialists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of skilled fetal medicine specialists has
                      extensive experience in diagnosing and managing a wide
                      range of fetal conditions, providing expert care tailored
                      to your needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our care involves a collaborative team of specialists,
                      including obstetricians, genetic counselors, and
                      neonatologists, to ensure a comprehensive approach to
                      managing fetal health.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Diagnostic Technology
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest imaging and diagnostic technologies,
                      including high-resolution ultrasounds and advanced fetal
                      monitoring, to provide accurate assessments and timely
                      interventions.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Compassionate and Personalized Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer compassionate support and personalized care
                      plans, focusing on your unique situation and providing the
                      information and resources you need for informed
                      decision-making.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 21 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Family planning
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      We offer a range of family planning services designed to
                      support individuals and couples in making informed choices
                      about their reproductive health. Our Family Planning
                      Department is dedicated to providing personalized,
                      compassionate care to help you achieve your family
                      planning goals, whether you’re looking to start a family,
                      space out pregnancies, or manage reproductive health.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Care Providers
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of skilled healthcare professionals includes
                      obstetricians, gynecologists, and family planning
                      specialists who offer expert guidance and care tailored to
                      your needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Wide Range of Options
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We provide a comprehensive range of family planning
                      methods and services, including contraceptive options,
                      fertility planning, and preconception counseling.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Consultation
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer personalized consultations to help you explore
                      and choose the best family planning options based on your
                      health, lifestyle, and reproductive goals.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Confidential and Supportive Environment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our services are provided in a confidential and supportive
                      environment, ensuring that you receive the care and
                      information you need with respect and privacy.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 22 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Adolescent Health
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      we are dedicated to providing exceptional care tailored to
                      the unique health needs of adolescents. Our Adolescent
                      Health Department focuses on promoting physical,
                      emotional, and mental well-being during these formative
                      years. With a team of specialized healthcare providers and
                      a range of services designed to support adolescents
                      through their teenage years, we are here to ensure a
                      healthy and positive transition into adulthood.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Adolescent Specialists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of healthcare professionals, including
                      pediatricians, endocrinologists, and mental health
                      specialists, is experienced in addressing the specific
                      health needs of adolescents.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Holistic Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We take a comprehensive approach to adolescent health,
                      addressing physical, emotional, and social aspects to
                      support overall well-being and development.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Confidential and Supportive Environment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We provide care in a confidential, respectful, and
                      supportive environment, helping adolescents feel
                      comfortable discussing their health concerns and seeking
                      guidance.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Education and Empowerment
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We emphasize education and empowerment, providing
                      adolescents with the information and resources they need
                      to make informed decisions about their health and
                      lifestyle.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 23 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Well woman clinic
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      Our Well Woman Clinic is dedicated to providing
                      comprehensive health care tailored to the unique needs of
                      women at every stage of life. From routine check-ups to
                      specialized care, our clinic focuses on promoting overall
                      well-being and addressing specific health concerns. Our
                      team of experienced professionals is committed to
                      delivering compassionate, personalized care to help you
                      maintain optimal health and live your best life.
                    </p>
                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Healthcare Providers
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team includes skilled gynecologists, internists, and
                      other specialists who offer expert care and advice on a
                      wide range of women’s health issues.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Services
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We provide a full spectrum of services, from preventive
                      care and screenings to management of chronic conditions
                      and personalized health counseling.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize your individual needs and preferences,
                      offering tailored care plans and a supportive environment
                      where you can discuss any health concerns.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our clinic is equipped with the latest technology and
                      facilities to ensure accurate assessments and effective
                      treatments.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 24 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Vaccination
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      We offer a wide range of vaccination services to help
                      protect you and your loved ones from preventable diseases.
                      Our vaccination program is designed to provide safe,
                      effective, and up-to-date immunizations, ensuring you
                      receive the best possible protection against infectious
                      diseases. Our team of healthcare professionals is
                      dedicated to delivering expert care and guidance on all
                      aspects of vaccination.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Healthcare Providers
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our skilled healthcare professionals are trained in
                      administering a variety of vaccines and providing guidance
                      on vaccination schedules and requirements.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Vaccine Offerings
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer a full range of vaccines for all ages, from
                      childhood immunizations to adult and travel vaccines.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Safe and Effective Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We adhere to the highest standards of safety and
                      effectiveness in administering vaccines, using up-to-date,
                      approved vaccines and protocols.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We provide personalized care, addressing any questions or
                      concerns you may have about vaccines and ensuring you
                      receive the appropriate immunizations for your needs.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 25 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      NICU
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2 pt-2 lg:text-lg text-base">
                      Our Neonatal Intensive Care Unit (NICU) is dedicated to
                      providing expert, compassionate care for newborns who
                      require specialized medical attention. Our NICU is
                      equipped with the latest technology and staffed by a team
                      of highly trained professionals to ensure the best
                      possible outcomes for critically ill or premature infants.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Neonatologists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of board-certified neonatologists and specialized
                      healthcare providers offers expert care for newborns with
                      complex medical needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      State-of-the-Art Facilities
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our NICU features cutting-edge technology and advanced
                      medical equipment to support the intensive care needs of
                      newborns, including incubators, ventilators, and
                      monitoring systems.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Family-Centered Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize a family-centered approach, involving
                      parents and caregivers in the care process and providing
                      support and education throughout the NICU stay.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Team
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our NICU team includes neonatal nurses, respiratory
                      therapists, lactation consultants, and other specialists
                      working collaboratively to deliver comprehensive care.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 26 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Cosmetology
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      Our Cosmetology Department offers a range of advanced
                      cosmetic and dermatological services designed to enhance
                      your appearance and boost your confidence. Our team of
                      experienced dermatologists and cosmetic specialists
                      provides personalized care using the latest technologies
                      and techniques to address your unique aesthetic needs.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Cosmetic Specialists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of certified cosmetic dermatologists and
                      specialists is skilled in providing high-quality care
                      tailored to your individual needs and goals.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Technology
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest in cosmetic technology and
                      techniques to deliver effective and safe treatments,
                      ensuring the best possible results.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We offer personalized consultations and treatment plans,
                      focusing on your specific concerns and aesthetic goals to
                      achieve the desired outcomes.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From non-invasive procedures to advanced dermatological
                      treatments, we provide a wide range of services to meet
                      all your cosmetic needs.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 27 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      General Surgery
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      Our General Surgery Department is dedicated to providing
                      high-quality surgical care for a wide range of conditions.
                      Our team of experienced general surgeons and specialized
                      healthcare professionals is committed to delivering
                      effective, personalized treatment with a focus on your
                      safety and well-being
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Experienced Surgeons
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of board-certified general surgeons brings
                      extensive experience and expertise in performing a variety
                      of surgical procedures.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Surgical Techniques
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest surgical technologies and minimally
                      invasive techniques to enhance precision, reduce recovery
                      times, and improve patient outcomes.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From pre-surgical consultations to post-operative
                      follow-up, we provide comprehensive care to support you
                      through every stage of your surgical journey
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize your comfort and involve you in the
                      decision-making process, ensuring that your treatment plan
                      aligns with your needs and preferences.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                {activeToggle === 28 && (
                  <div className="">
                    <h1 className="xl:text-2xl text-iceblue  font-bold lg:text-2xl text-2xl">
                      Urology and Urogynaecology
                    </h1>
                    <p className="xl:text-lg text-grey-600 xl:pt-2  pt-2 lg:text-lg text-base">
                      Our Urology and Urogynaecology Departments provide
                      specialized care for a wide range of conditions affecting
                      the urinary tract and pelvic floor. Our expert team of
                      urologists and urogynaecologists is dedicated to
                      delivering high-quality, personalized treatment to address
                      your specific needs and improve your quality of life.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Expert Specialists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team of board-certified urologists and
                      urogynaecologists brings extensive experience and
                      expertise in diagnosing and treating complex conditions
                      related to the urinary and reproductive systems.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Treatment Options
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We use the latest technology and minimally invasive
                      techniques to provide effective treatments, ensuring the
                      best possible outcomes with minimal disruption to your
                      daily life.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Patient-Centered Care
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We prioritize your comfort and involve you in every step
                      of your care plan, offering personalized treatment options
                      and support throughout your journey.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Comprehensive Services
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      From routine consultations to advanced surgical
                      procedures, we offer a full spectrum of services to
                      address all aspects of urological and urogynaecological
                      health.
                    </p>

                    <div className="flex xl:mt-4 mt-4">
                      <a href="/contact?">
                        <button
                          className="bg-gradient px-6 py-2 text-white"
                          style={{ outline: "none" }}
                        >
                          Book an Appointment
                        </button>
                      </a>
                      <button
                        className="text-iceblue border-8 px-6 py-2 ml-2"
                        style={{ outline: "none" }}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </div>
                )}

                <h1 className="xl:text-2xl text-iceblue xl:mt-8 text-2xl mt-8 figtree">
                  Frequently Asked <span className="font-bold">Questions</span>
                </h1>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-8 mt-8"
                    onClick={() => handleToggle(4)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Do I need to make an appointment before visiting the
                      hospital?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 4 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 4 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we recommend scheduling appointments in advance to
                        reduce wait times. You can book appointments via our
                        website, mobile app, or by calling the hospital’s
                        appointment desk.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(5)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Is the hospital open 24/7?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 5 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 5 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, the hospital operates 24/7, including emergency
                        services, which are available round the clock for both
                        women and children.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(6)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      What maternity services do you offer?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 6 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 6 && (
                    <div className="mt-4">
                      <p className="text-base">
                        We provide a full range of maternity services, including
                        prenatal care, labor and delivery, postpartum care, and
                        lactation support. Our team includes obstetricians,
                        midwives, and pediatricians to ensure a safe delivery.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(7)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Do you have specialists for high-risk pregnancies?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 7 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 7 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we have experienced maternal-fetal medicine
                        specialists for managing high-risk pregnancies. Our NICU
                        (Neonatal Intensive Care Unit) is also equipped to
                        handle premature or critically ill newborns.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(8)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Can I choose a birthing plan?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 8 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 8 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we encourage expectant mothers to discuss their
                        birth plan with our obstetricians. We support natural
                        births, epidurals, C-sections, and water births based on
                        your preferences and medical conditions.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(9)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      What pediatric services are available?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 9 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 9 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Our hospital offers comprehensive pediatric care,
                        including routine checkups, vaccinations, and treatment
                        for common childhood illnesses. We also have specialists
                        in pediatric surgery, cardiology, neurology, and more.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4"
                    onClick={() => handleToggle(10)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Do you offer immunizations for children?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 10 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 10 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we follow the national immunization schedule and
                        offer all necessary vaccinations for children. You can
                        consult with our pediatricians to plan your child’s
                        immunization schedule.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer xl:mt-4 mt-4"
                    onClick={() => handleToggle(11)}
                  >
                    <h1 className="xl:text-lg font-semibold text-black lg:text-lg text-base">
                      Is there a pediatric emergency department?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 11 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 11 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we have a dedicated pediatric emergency department
                        staffed by specialized doctors and nurses to handle any
                        urgent health concerns for children.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*tab and mobile view*/}

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade12  pb-12">
        <div className="container mx-auto px-4">
          <div className="  pt-12">
            {/*mobile section 1st div*/}
            <div className="">
              <div className="w-12/12 sticky overflow-hidden">
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer pr-4"
                    onClick={() => handleToggle(0)}
                  >
                    <h1 className="font-bold text-iceblue text-lg">
                      Fertility
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>

                  {activeToggle === 0 && (
                    <div>
                      {/* <h1 className="text-iceblue ml-2 font-bold text-xl mt-4">
            Fertility
          </h1> */}

                      <p className="text-grey-600 pt-2 text-base">
                        we are dedicated to helping individuals and couples
                        realize their dreams of parenthood. As a leading
                        fertility hospital, we provide personalized,
                        compassionate, and advanced fertility treatments,
                        supported by a team of expert reproductive specialists.
                        Whether you are just beginning your fertility journey or
                        seeking advanced reproductive technologies, we are here
                        to support you every step of the way.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        {" "}
                        World-Class Fertility Experts
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of experienced fertility specialists,
                        embryologists, and counselors are pioneers in
                        reproductive medicine, offering cutting-edge treatments
                        tailored to your unique needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        {" "}
                        Advanced Reproductive Technology
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We employ the latest technologies, including IVF, ICSI,
                        genetic testing, and cryopreservation, ensuring the
                        highest success rates and the best possible care.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Treatment Plans
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Every fertility journey is unique. We create customized
                        treatment plans that cater to your specific medical,
                        emotional, and lifestyle needs, giving you the best
                        chance at success.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From fertility assessments to fertility preservation, we
                        offer a full spectrum of services designed to support
                        you on your path to parenthood. Our holistic approach
                        also includes emotional support and wellness services.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(1)}
                  >
                    <h1 className="font-bold text-iceblue text-lg text-base">
                      Paediatrics
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 1 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold text-2xl ">
                      Paediatrics
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We understand that children are special, and so are
                        their healthcare needs. Our dedicated team of pediatric
                        specialists, nurses, and healthcare professionals are
                        committed to providing the highest quality of medical
                        care in a comforting, child-friendly environment. We
                        focus not only on treating illnesses but also on the
                        holistic development and well-being of every child.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Pediatric Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From newborns to adolescents, we provide a full spectrum
                        of pediatric services, ensuring your child receives
                        age-appropriate and specialized care.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Experienced Pediatric Team
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our pediatricians, surgeons, and specialists are highly
                        experienced in handling a wide range of conditions. We
                        focus on preventative care, early diagnosis, and
                        advanced treatments.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Child-Friendly Environment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our hospital is designed with children in mind – with
                        colorful play areas, comfortable patient rooms, and
                        staff trained to reduce anxiety in young patients.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        24/7 Pediatric Emergency Services
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Medical emergencies can happen at any time. Our
                        emergency department is open 24/7, staffed with
                        pediatric specialists trained to handle everything from
                        injuries to severe illnesses.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(2)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Dermatology
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 2 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 2 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold  text-2xl">
                      Dermatology
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We specialize in providing comprehensive dermatological
                        care for all age groups, offering advanced treatments
                        for skin, hair, and nail conditions. Whether you need
                        help managing chronic skin conditions, cosmetic
                        treatments, or minor skin surgeries, our team of expert
                        dermatologists is here to ensure your skin remains
                        healthy and radiant.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Dermatologists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of board-certified dermatologists is trained in
                        both medical and cosmetic dermatology, ensuring accurate
                        diagnosis and effective treatment plans tailored to your
                        unique needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Cutting-Edge Technology
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest advancements in dermatological
                        technology, including laser treatments, phototherapy,
                        and minimally invasive cosmetic procedures, to deliver
                        the best possible outcomes.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Skin Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From acne management to skin cancer screenings, we offer
                        a full range of dermatology services to address both
                        routine and complex skin concerns.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We believe in providing personalized care in a
                        comfortable, compassionate environment, ensuring our
                        patients feel supported throughout their treatment
                        journey.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(3)}
                  >
                    <h1 className=" font-bold text-iceblue text-lg text-base">
                      Postnatal Care
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 3 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 3 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold text-2xl">
                      Postnatal Care
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we understand that the postnatal period is a crucial
                        time for both mother and baby. Our expert team is
                        dedicated to ensuring a smooth and healthy transition
                        from pregnancy to parenthood by providing comprehensive
                        care that promotes the well-being of both the mother and
                        the newborn. Our goal is to support, educate, and
                        empower you during this beautiful journey.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Experienced Medical Team
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of obstetricians, neonatologists,
                        pediatricians, and nurses specializes in postnatal care
                        to ensure the best possible support and treatment for
                        both mother and baby.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Holistic Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer a range of services addressing the physical,
                        emotional, and psychological needs of new mothers, with
                        a strong focus on recovery, breastfeeding support, and
                        baby care education.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Family-Centered Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We believe in involving the whole family in postnatal
                        care, offering education and support for fathers,
                        partners, and extended family members to ensure a
                        nurturing and supportive environment for both mother and
                        baby.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comfortable Environment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our postnatal wards are designed to offer a calm,
                        nurturing, and private environment to help new mothers
                        recover and bond with their newborns. Rooming-in
                        facilities are available for families.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(12)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Laparoscopic Surgery
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 12 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 12 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold  text-2xl">
                      Laparoscopic Surgery
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we are committed to offering the latest in minimally
                        invasive surgical techniques, ensuring quicker recovery
                        times, less pain, and minimal scarring. Laparoscopic
                        surgery, also known as keyhole surgery, is a
                        revolutionary method used for various surgical
                        procedures. With our team of highly skilled surgeons and
                        cutting-edge technology, we provide safe, effective
                        solutions for a wide range of medical conditions.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Surgical Team
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our surgeons are highly trained in laparoscopic
                        procedures, offering years of experience in handling
                        complex surgeries with precision and care.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest laparoscopic equipment and
                        technology, ensuring the highest standards of care and
                        safety for our patients.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Minimally Invasive Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Laparoscopic surgery involves smaller incisions, which
                        result in reduced pain, quicker recovery, and minimal
                        scarring compared to traditional open surgery.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We focus on personalized care, from diagnosis and
                        surgical planning to post-operative recovery, ensuring
                        that each patient receives comprehensive and
                        compassionate treatment.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(13)}
                  >
                    <h1 className="text-lg font-bold text-iceblue text-base">
                      Ultrasonography
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 13 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 13 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold  text-2xl">
                      Ultrasonography
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we are committed to offering the latest in diagnostic
                        imaging technology through our comprehensive
                        ultrasonography services. Ultrasonography, also known as
                        ultrasound, is a non-invasive imaging technique used to
                        visualize internal organs and structures, helping in the
                        diagnosis and monitoring of various medical conditions.
                        Our expert radiologists and sonographers ensure accurate
                        and detailed imaging, supporting the best clinical
                        outcomes for our patients.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Ultrasound Technology
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our hospital is equipped with state-of-the-art
                        ultrasound machines that provide high-resolution imaging
                        for detailed and accurate diagnosis.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Experienced Radiologists and Sonographers
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of highly trained radiologists and certified
                        sonographers specializes in performing and interpreting
                        ultrasound scans, ensuring precise and reliable results.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Non-Invasive and Safe
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Ultrasound is a safe and painless imaging technique that
                        does not use radiation, making it an ideal diagnostic
                        tool for a wide range of conditions, including pregnancy
                        monitoring and organ assessment.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Imaging Services
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer a broad spectrum of ultrasound services, from
                        routine pregnancy scans to specialized imaging for
                        various medical conditions, ensuring a holistic approach
                        to diagnostics.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(14)}
                  >
                    <h1 className="text-lg font-bold text-iceblue">
                      Physiotherapy
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 14 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 14 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold  text-2xl">
                      Pysiotherapy
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we are dedicated to providing top-tier physiotherapy
                        services that help patients restore movement, reduce
                        pain, and improve overall physical function. Our team of
                        highly trained physiotherapists specializes in designing
                        individualized treatment plans that cater to your
                        specific needs, whether you are recovering from surgery,
                        dealing with a sports injury, or managing a chronic
                        condition.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Physiotherapists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team consists of certified and experienced
                        physiotherapists who are committed to helping you
                        achieve optimal physical health and wellness.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest equipment and rehabilitation
                        technologies to provide advanced treatment options that
                        enhance recovery and maximize results.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Treatment Plans
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Every patient receives a tailored physiotherapy plan
                        that addresses their unique medical condition, physical
                        goals, and lifestyle needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Holistic Approach to Healing
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We focus on a holistic approach that integrates manual
                        therapy, exercise, and patient education, ensuring
                        long-term recovery and health maintenance.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(15)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Neonatology
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 15 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 15 && (
                    <div className=" mt-4">
                      {/* <h1 className=" text-iceblue ml-2 font-bold  text-2xl ">
                      Neonatology
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We specialize in providing advanced medical care for
                        newborns, particularly premature or critically ill
                        infants, through our state-of-the-art Neonatology
                        Department. Our dedicated team of neonatologists,
                        nurses, and specialists are committed to offering
                        round-the-clock care, ensuring that every newborn
                        receives the specialized attention they need for a
                        healthy start in life.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Neonatologists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of highly skilled neonatologists has extensive
                        experience in managing complex conditions in newborns,
                        ensuring the highest standard of care.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Neonatal Intensive Care Unit (NICU)
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our hospital is equipped with a modern NICU, offering
                        the most advanced technology for monitoring, diagnosing,
                        and treating critically ill and premature newborns.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Compassionate, Family-Centered Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize family involvement in neonatal care,
                        providing emotional support and education to help
                        parents navigate their newborn’s medical journey with
                        confidence.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Multidisciplinary Team Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our care extends beyond neonatologists, including
                        pediatric surgeons, cardiologists, neurologists, and
                        nutritionists, who collaborate to offer comprehensive
                        care for every baby.
                      </p>

                      <div className="flex  mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(16)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      ANC Care
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 16 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 16 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      ANC Care
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We are dedicated to providing exceptional antenatal care
                        to support a healthy pregnancy and ensure the well-being
                        of both mother and baby. Our Antenatal Care (ANC)
                        services encompass a wide range of medical, educational,
                        and emotional support tailored to meet the unique needs
                        of each expecting mother. From early pregnancy through
                        labor and delivery, our team is here to guide and
                        support you every step of the way.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Obstetricians
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of experienced obstetricians provides expert
                        care, closely monitoring your health and your baby’s
                        development throughout your pregnancy.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Care Plans
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We develop individualized antenatal care plans based on
                        your medical history, health needs, and personal
                        preferences to ensure the best outcomes for you and your
                        baby.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our hospital is equipped with advanced diagnostic and
                        monitoring technologies to provide comprehensive and
                        accurate assessments of your pregnancy
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Supportive and Caring Team
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From initial consultations to delivery, our dedicated
                        nurses, midwives, and support staff offer compassionate
                        care and support to make your pregnancy journey as
                        smooth as possible.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(17)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      High Risk Pregnancy
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 17 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 17 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      High Risk Pregnancy
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We understand that a high-risk pregnancy requires
                        specialized care and attention to ensure the health and
                        safety of both mother and baby. Our High-Risk Pregnancy
                        Department is dedicated to providing comprehensive,
                        expert care for pregnancies that present additional
                        challenges. With a team of experienced specialists,
                        advanced diagnostic tools, and personalized treatment
                        plans, we are committed to supporting you through every
                        stage of your high-risk pregnancy journey.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Specialized Obstetricians
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of high-risk obstetricians has extensive
                        experience in managing complex pregnancies, offering
                        expert care and advanced treatment options tailored to
                        your needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Diagnostic and Monitoring Technologies
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We use state-of-the-art technologies, including advanced
                        imaging and genetic testing, to monitor your pregnancy
                        and identify any potential issues early.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Multidisciplinary Team Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our approach involves a collaborative team of
                        specialists, including maternal-fetal medicine experts,
                        neonatologists, and other healthcare professionals, to
                        provide holistic care and achieve the best outcomes.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Care Plans
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We develop individualized care plans that address your
                        specific health concerns and pregnancy challenges,
                        ensuring a tailored approach to manage risks and support
                        a healthy pregnancy.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(18)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Obstetrics
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 18 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 18 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Obstetrics
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we offer exceptional obstetric care to support you
                        through the entire journey of pregnancy, from conception
                        to postpartum. Our Obstetrics Department is dedicated to
                        providing comprehensive, personalized care to ensure the
                        health and well-being of both you and your baby. With a
                        team of skilled obstetricians, advanced technology, and
                        a compassionate approach, we are here to guide you
                        through every step of your pregnancy experience.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Experienced Obstetricians
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of experienced obstetricians provides expert
                        care and support throughout your pregnancy, ensuring the
                        best outcomes for both mother and baby.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Care Plans
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We create individualized care plans based on your health
                        needs, pregnancy progression, and personal preferences
                        to provide tailored support and guidance.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our hospital is equipped with the latest technology and
                        facilities to offer advanced diagnostic, monitoring, and
                        treatment options for all aspects of obstetric care.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Supportive and Caring Environment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize a compassionate and supportive
                        environment, focusing on your comfort and well-being
                        throughout your pregnancy journey.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(19)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Fetal Medicine
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 19 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 19 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Fetal Medicine
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we provide specialized care in fetal medicine to ensure
                        the health and well-being of your baby throughout
                        pregnancy. Our Fetal Medicine Department is dedicated to
                        offering comprehensive, cutting-edge care for the
                        management and monitoring of fetal conditions and
                        complications. With a team of expert fetal medicine
                        specialists and advanced diagnostic technologies, we are
                        here to support you and your baby every step of the way.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Fetal Medicine Specialists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of skilled fetal medicine specialists has
                        extensive experience in diagnosing and managing a wide
                        range of fetal conditions, providing expert care
                        tailored to your needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Multidisciplinary Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our care involves a collaborative team of specialists,
                        including obstetricians, genetic counselors, and
                        neonatologists, to ensure a comprehensive approach to
                        managing fetal health.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Diagnostic Technology
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest imaging and diagnostic
                        technologies, including high-resolution ultrasounds and
                        advanced fetal monitoring, to provide accurate
                        assessments and timely interventions.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Compassionate and Personalized Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer compassionate support and personalized care
                        plans, focusing on your unique situation and providing
                        the information and resources you need for informed
                        decision-making.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(20)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Family planning
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 20 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 20 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Family planning
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We offer a range of family planning services designed to
                        support individuals and couples in making informed
                        choices about their reproductive health. Our Family
                        Planning Department is dedicated to providing
                        personalized, compassionate care to help you achieve
                        your family planning goals, whether you’re looking to
                        start a family, space out pregnancies, or manage
                        reproductive health.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Care Providers
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of skilled healthcare professionals includes
                        obstetricians, gynecologists, and family planning
                        specialists who offer expert guidance and care tailored
                        to your needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Wide Range of Options
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We provide a comprehensive range of family planning
                        methods and services, including contraceptive options,
                        fertility planning, and preconception counseling.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Consultation
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer personalized consultations to help you explore
                        and choose the best family planning options based on
                        your health, lifestyle, and reproductive goals.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Confidential and Supportive Environment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our services are provided in a confidential and
                        supportive environment, ensuring that you receive the
                        care and information you need with respect and privacy.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(21)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Adolescent Health
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 21 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 21 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Adolescent Health
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        we are dedicated to providing exceptional care tailored
                        to the unique health needs of adolescents. Our
                        Adolescent Health Department focuses on promoting
                        physical, emotional, and mental well-being during these
                        formative years. With a team of specialized healthcare
                        providers and a range of services designed to support
                        adolescents through their teenage years, we are here to
                        ensure a healthy and positive transition into adulthood.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Adolescent Specialists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of healthcare professionals, including
                        pediatricians, endocrinologists, and mental health
                        specialists, is experienced in addressing the specific
                        health needs of adolescents.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Holistic Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We take a comprehensive approach to adolescent health,
                        addressing physical, emotional, and social aspects to
                        support overall well-being and development.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Confidential and Supportive Environment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We provide care in a confidential, respectful, and
                        supportive environment, helping adolescents feel
                        comfortable discussing their health concerns and seeking
                        guidance.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Education and Empowerment
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We emphasize education and empowerment, providing
                        adolescents with the information and resources they need
                        to make informed decisions about their health and
                        lifestyle.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(22)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Well woman clinic
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 22 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 22 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Well woman clinic
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        Our Well Woman Clinic is dedicated to providing
                        comprehensive health care tailored to the unique needs
                        of women at every stage of life. From routine check-ups
                        to specialized care, our clinic focuses on promoting
                        overall well-being and addressing specific health
                        concerns. Our team of experienced professionals is
                        committed to delivering compassionate, personalized care
                        to help you maintain optimal health and live your best
                        life.
                      </p>
                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Healthcare Providers
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team includes skilled gynecologists, internists, and
                        other specialists who offer expert care and advice on a
                        wide range of women’s health issues.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Services
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We provide a full spectrum of services, from preventive
                        care and screenings to management of chronic conditions
                        and personalized health counseling.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize your individual needs and preferences,
                        offering tailored care plans and a supportive
                        environment where you can discuss any health concerns.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our clinic is equipped with the latest technology and
                        facilities to ensure accurate assessments and effective
                        treatments.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(23)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Vaccination
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 23 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 23 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Vaccination
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        We offer a wide range of vaccination services to help
                        protect you and your loved ones from preventable
                        diseases. Our vaccination program is designed to provide
                        safe, effective, and up-to-date immunizations, ensuring
                        you receive the best possible protection against
                        infectious diseases. Our team of healthcare
                        professionals is dedicated to delivering expert care and
                        guidance on all aspects of vaccination.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Healthcare Providers
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our skilled healthcare professionals are trained in
                        administering a variety of vaccines and providing
                        guidance on vaccination schedules and requirements.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Vaccine Offerings
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer a full range of vaccines for all ages, from
                        childhood immunizations to adult and travel vaccines.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Safe and Effective Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We adhere to the highest standards of safety and
                        effectiveness in administering vaccines, using
                        up-to-date, approved vaccines and protocols.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We provide personalized care, addressing any questions
                        or concerns you may have about vaccines and ensuring you
                        receive the appropriate immunizations for your needs.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(24)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      NICU
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 24 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 24 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      NICU
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        Our Neonatal Intensive Care Unit (NICU) is dedicated to
                        providing expert, compassionate care for newborns who
                        require specialized medical attention. Our NICU is
                        equipped with the latest technology and staffed by a
                        team of highly trained professionals to ensure the best
                        possible outcomes for critically ill or premature
                        infants.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Neonatologists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of board-certified neonatologists and
                        specialized healthcare providers offers expert care for
                        newborns with complex medical needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        State-of-the-Art Facilities
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our NICU features cutting-edge technology and advanced
                        medical equipment to support the intensive care needs of
                        newborns, including incubators, ventilators, and
                        monitoring systems.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Family-Centered Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize a family-centered approach, involving
                        parents and caregivers in the care process and providing
                        support and education throughout the NICU stay.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Multidisciplinary Team
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our NICU team includes neonatal nurses, respiratory
                        therapists, lactation consultants, and other specialists
                        working collaboratively to deliver comprehensive care.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(25)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Cosmetology
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 25 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 25 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Cosmetology
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        Our Cosmetology Department offers a range of advanced
                        cosmetic and dermatological services designed to enhance
                        your appearance and boost your confidence. Our team of
                        experienced dermatologists and cosmetic specialists
                        provides personalized care using the latest technologies
                        and techniques to address your unique aesthetic needs.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Cosmetic Specialists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of certified cosmetic dermatologists and
                        specialists is skilled in providing high-quality care
                        tailored to your individual needs and goals.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Technology
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest in cosmetic technology and
                        techniques to deliver effective and safe treatments,
                        ensuring the best possible results.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Personalized Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We offer personalized consultations and treatment plans,
                        focusing on your specific concerns and aesthetic goals
                        to achieve the desired outcomes.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From non-invasive procedures to advanced dermatological
                        treatments, we provide a wide range of services to meet
                        all your cosmetic needs.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(26)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      General Surgery
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 26 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 26 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      General Surgery
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        Our General Surgery Department is dedicated to providing
                        high-quality surgical care for a wide range of
                        conditions. Our team of experienced general surgeons and
                        specialized healthcare professionals is committed to
                        delivering effective, personalized treatment with a
                        focus on your safety and well-being
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Experienced Surgeons
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of board-certified general surgeons brings
                        extensive experience and expertise in performing a
                        variety of surgical procedures.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Surgical Techniques
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We utilize the latest surgical technologies and
                        minimally invasive techniques to enhance precision,
                        reduce recovery times, and improve patient outcomes.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From pre-surgical consultations to post-operative
                        follow-up, we provide comprehensive care to support you
                        through every stage of your surgical journey
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Approach
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize your comfort and involve you in the
                        decision-making process, ensuring that your treatment
                        plan aligns with your needs and preferences.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(27)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                      Urology and Urogynaecology
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 27 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 27 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Urology and Urogynaecology
                    </h1> */}
                      <p className="text-grey-600 pt-2 text-base">
                        Our Urology and Urogynaecology Departments provide
                        specialized care for a wide range of conditions
                        affecting the urinary tract and pelvic floor. Our expert
                        team of urologists and urogynaecologists is dedicated to
                        delivering high-quality, personalized treatment to
                        address your specific needs and improve your quality of
                        life.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Expert Specialists
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        Our team of board-certified urologists and
                        urogynaecologists brings extensive experience and
                        expertise in diagnosing and treating complex conditions
                        related to the urinary and reproductive systems.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Advanced Treatment Options
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We use the latest technology and minimally invasive
                        techniques to provide effective treatments, ensuring the
                        best possible outcomes with minimal disruption to your
                        daily life.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Patient-Centered Care
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        We prioritize your comfort and involve you in every step
                        of your care plan, offering personalized treatment
                        options and support throughout your journey.
                      </p>

                      <h1 className="text-lg font-bold mt-4 text-iceblue ">
                        Comprehensive Services
                      </h1>
                      <p className="text-grey-600 pt-2 text-base">
                        From routine consultations to advanced surgical
                        procedures, we offer a full spectrum of services to
                        address all aspects of urological and urogynaecological
                        health.
                      </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>


                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer mt-4 pr-4"
                    onClick={() => handleToggle(29)}
                  >
                    <h1 className="text-lg font-bold text-iceblue  text-base">
                    Gynecological Oncology
                    </h1>
                    <p className="text-xl font-bold text-iceblue">
                      {activeToggle === 29 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </p>
                  </div>
                  {activeToggle === 29 && (
                    <div className=" mt-4">
                      {/* <h1 className="xl:text-2xl text-iceblue ml-2 font-bold  text-2xl">
                      Urology and Urogynaecology
                    </h1> */}
                      <p className=" text-grey-600   pt-2  text-base">
                      We offer specialized care for women facing gynecological
                      cancers through our dedicated Gynecological Oncology
                      Department. Our mission is to provide comprehensive,
                      personalized treatment for cancers affecting the female
                      reproductive system, including the ovaries, uterus,
                      cervix, vulva, and vagina. With a team of leading
                      specialists, state-of-the-art facilities, and a
                      patient-centered approach, we are committed to delivering
                      the highest standard of care and support.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Leading Oncologists
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our team includes renowned gynecological oncologists with
                      extensive experience in diagnosing and treating
                      gynecological cancers, ensuring expert care and advanced
                      treatment options.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Advanced Treatment Technologies
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We utilize the latest technologies and treatment
                      modalities, including minimally invasive surgical
                      techniques, targeted therapies, and cutting-edge radiation
                      therapies.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Personalized Care Plans
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      We develop individualized treatment plans tailored to each
                      patient’s specific needs, ensuring a comprehensive
                      approach that addresses their unique medical condition and
                      personal preferences.
                    </p>

                    <h1 className="text-lg font-bold mt-4 text-iceblue ">
                      Multidisciplinary Team Approach
                    </h1>
                    <p className="text-grey-600 pt-2 text-base">
                      Our care involves a collaborative team of oncologists,
                      surgeons, radiologists, pathologists, and support staff,
                      working together to provide holistic care and achieve the
                      best outcomes.
                    </p>

                      <div className="flex mt-4">
                        <a href="/contact?">
                          <button className="bg-gradient px-2 py-2 text-white">
                            Book an Appointment
                          </button>
                        </a>
                        <button className="text-iceblue border-8 px-2 py-2 ml-2">
                          Request a Callback
                        </button>
                      </div>
                    </div>
                  )}
                </div>







              </div>
            </div>

            {/*mobile section 2nd div*/}
            <div
              className="bg-white  p-6 pt-8 mt-8"
              style={{
                width: "100%",
                backgroundColor: "fffff",
                height: "fit-content",
                backgroundSize: "auto", // or "contain" depending on the desired effect
                backgroundPosition: "top right",
                backgroundRepeat: "no-repeat",

                overflowY: "scroll", // Enables vertical scrolling
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none", // For Internet Explorer and Edge
              }}
            >
              <div className="">
                <h1 className=" text-iceblue  text-2xl mt-2">
                  Frequently Asked <span className="font-bold">Questions</span>
                </h1>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-8"
                    onClick={() => handleToggle(4)}
                  >
                    <h1 className=" font-semibold text-black  text-base">
                      Do I need to make an appointment before visiting the
                      hospital?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 4 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 4 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we recommend scheduling appointments in advance to
                        reduce wait times. You can book appointments via our
                        website, mobile app, or by calling the hospital’s
                        appointment desk.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(5)}
                  >
                    <h1 className=" font-semibold text-black lg:text-lg text-base">
                      Is the hospital open 24/7?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 5 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 5 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, the hospital operates 24/7, including emergency
                        services, which are available round the clock for both
                        women and children.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(6)}
                  >
                    <h1 className=" font-semibold text-black  text-base">
                      What maternity services do you offer?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 6 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 6 && (
                    <div className="mt-4">
                      <p className="text-base">
                        We provide a full range of maternity services, including
                        prenatal care, labor and delivery, postpartum care, and
                        lactation support. Our team includes obstetricians,
                        midwives, and pediatricians to ensure a safe delivery.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(7)}
                  >
                    <h1 className="font-semibold text-black text-base">
                      Do you have specialists for high-risk pregnancies?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 7 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 7 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we have experienced maternal-fetal medicine
                        specialists for managing high-risk pregnancies. Our NICU
                        (Neonatal Intensive Care Unit) is also equipped to
                        handle premature or critically ill newborns.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(8)}
                  >
                    <h1 className=" font-semibold text-black  text-base">
                      Can I choose a birthing plan?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 8 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 8 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we encourage expectant mothers to discuss their
                        birth plan with our obstetricians. We support natural
                        births, epidurals, C-sections, and water births based on
                        your preferences and medical conditions.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(9)}
                  >
                    <h1 className=" font-semibold text-black  text-base">
                      What pediatric services are available?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 9 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 9 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Our hospital offers comprehensive pediatric care,
                        including routine checkups, vaccinations, and treatment
                        for common childhood illnesses. We also have specialists
                        in pediatric surgery, cardiology, neurology, and more.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer "
                    onClick={() => handleToggle(10)}
                  >
                    <h1 className=" font-semibold text-black text-base">
                      Do you offer immunizations for children?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 10 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 10 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we follow the national immunization schedule and
                        offer all necessary vaccinations for children. You can
                        consult with our pediatricians to plan your child’s
                        immunization schedule.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>

                <div className="">
                  <div
                    className="flex justify-between items-center cursor-pointer  mt-4"
                    onClick={() => handleToggle(11)}
                  >
                    <h1 className=" font-semibold text-black  text-base">
                      Is there a pediatric emergency department?
                    </h1>
                    <p className="text-xl font-bold text-black">
                      {activeToggle === 11 ? "−" : "+"}
                    </p>
                  </div>

                  {activeToggle === 11 && (
                    <div className="mt-4">
                      <p className="text-base">
                        Yes, we have a dedicated pediatric emergency department
                        staffed by specialized doctors and nurses to handle any
                        urgent health concerns for children.
                      </p>
                    </div>
                  )}
                </div>

                <hr className="border-blue-50 mt-4"></hr>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </>
  );
}
