import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nuturehosiptal from "assets/img/Nurture-Hospital.webp";
import nurturepharmacy from "assets/img/Nurture-Hospital-pharmacy.webp";
import nurturereception from "assets/img/Nurture-Hospital-receptions.webp";

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    arrows: false, // Disable default arrows
  };

  return (
    <section className="container mx-auto figtree xl:block lg:block md:block sm:block block">
      <div>
        <Slider {...settings}>
          <div>
            <img className="xl:w-12/12 lg:w-12/12 w-12/12 md:w-12/12 rounded-lg  md:h-480 xl:h-400" src={nuturehosiptal} alt="Nurture Hospital" />
          </div>
          <div>
            <img className="xl:w-12/12 lg:w-12/12  w-12/12 md:w-12/12 rounded-lg md:h-480 xl:h-400" src={nurturepharmacy} alt="Nurture Pharmacy" />
          </div>
          <div>
            <img className="xl:w-12/12 lg:w-12/12 w-12/12 md:w-12/12 rounded-lg md:h-480 xl:h-400" src={nurturereception} alt="Nurture Reception" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default SliderPage;
