import React, { useState } from "react";
import Modal from "react-modal";
import gallery1 from "assets/img/galleryimg1.jpeg";
import gallery2 from "assets/img/galleryimg2.webp";
import gallery3 from "assets/img/galleryimg3.webp";
import gallery4 from "assets/img/galleryimg4.webp";
import gallery5 from "assets/img/galleryimg5.webp";
import gallery6 from "assets/img/galleryimg6.webp";
import gallery7 from "assets/img/galleryimg7.webp";
import gallery8 from "assets/img/galleryimg8.webp";
import gallery9 from "assets/img/galleryimg9.webp";
import gallery10 from "assets/img/galleryimg10.webp";
import gallery11 from "assets/img/galleryimg11.webp";
import gallery12 from "assets/img/galleryimg12.webp";
import gallery13 from "assets/img/galleryimg13.webp";
import gallery14 from "assets/img/galleryimg14.webp";
import gallery15 from "assets/img/galleryimg15.webp";
import gallery16 from "assets/img/galleryimg16.webp";
import gallery17 from "assets/img/galleryimg17.webp";
import gallery18 from "assets/img/galleryimg18.webp";
import gallery19 from "assets/img/galleryimg19.webp";
import gallery20 from "assets/img/galleryimg20.webp";
import gallery21 from "assets/img/galleryimg21.webp";
import gallery22 from "assets/img/galleryimg22.webp";
import gallery23 from "assets/img/galleryimg23.webp";
import gallery24 from "assets/img/galleryimg24.webp";
import gallery25 from "assets/img/galleryimg25.webp";
import gallery26 from "assets/img/galleryimg26.webp";
import gallery27 from "assets/img/galleryimg27.webp";
import gallery28 from "assets/img/galleryimg28.webp";
import gallery29 from "assets/img/galleryimg29.webp";
import gallery30 from "assets/img/galleryimg30.webp";
import gallery31 from "assets/img/galleryimg31.webp";
import gallery32 from "assets/img/galleryimg32.webp";
import deleteIcon from "assets/img/delete.png";

const images1 = [
  gallery12,
  gallery13,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  gallery20,
  gallery21,
  gallery22,
  gallery15,
  gallery14,
  gallery1,
];
const images2 = [gallery6, gallery2, gallery4, gallery5, , gallery7, gallery8];
const images3 = [
  gallery24,
  gallery25,
  gallery26,
  gallery27,
  gallery28,
  gallery29,
  gallery30,
  gallery31,
  gallery32,
  gallery13,
  gallery3,
  gallery23,
];

const GallerySlider1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState(images1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + currentImages.length) % currentImages.length
    );
  };

  return (
    <section className="xl:block lg:block md:block sm:block block container mx-auto xl:px-4 xl:mt-16 xl:pb-16 px-4 mt-12 lg:mt-12 lg:pb-16 pb-12">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:gap-0 lg:gap-0 md:gap-0 gap-4">
        {/* 1st div images */}
        <div>
          {images1.map((image, index) => (
            <div key={index} className="image-container1 img xl:mt-4 mt-4">
              <img
                className="xl:w-12/12 lg:w-12/12 md:w-12/12 cursor-pointer"
                src={image}
                alt=""
                onClick={() => {
                  setCurrentImages(images1);
                  openModal(index);
                }}
              />
            </div>
          ))}
        </div>

        {/* 2nd div images */}
        <div>
          {images2.map((image, index) => (
            <div key={index} className="image-container1 img xl:mt-4 mt-4">
              <img
                className="xl:w-12/12 lg:w-12/12 md:w-12/12 cursor-pointer"
                src={image}
                alt=""
                onClick={() => {
                  setCurrentImages(images2);
                  openModal(index);
                }}
              />
            </div>
          ))}
        </div>

        {/* 3rd div images */}
        <div>
          {images3.map((image, index) => (
            <div key={index} className="image-container1 img xl:mt-4 mt-4">
              <img
                className="xl:w-12/12 lg:w-12/12 md:w-12/12 cursor-pointer"
                src={image}
                alt=""
                onClick={() => {
                  setCurrentImages(images3);
                  openModal(index);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <button
          onClick={closeModal}
          className="close-button absolute top-4 right-4 z-50 text-lg lg:text-2xl"
        >
          x
        </button>

        <div className="modal-content">
          <button onClick={prevImage} className="nav-button">
            {"<"}
          </button>
          <img
            className="modal-image"
            src={currentImages[currentImageIndex]}
            alt={`gallery-${currentImageIndex}`}
          />
          <button onClick={nextImage} className="nav-button">
            {">"}
          </button>
        </div>
      </Modal>

      <style jsx>{`
        .modal {
          position: relative;
          max-width: 100%;
          max-height: 100%;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 4;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
        }

        .modal-content {
          display: flex;
          align-items: center;
          justify-content: space-between; /* Ensure arrows on left and right edges */
          position: relative;
          max-width: 90vw; /* Adjust max-width as needed */
          max-height: 90vh; /* Adjust max-height as needed */
          width: 100%;
          height: 100%;
        }

        .modal-image {
          max-width: 80%;
          max-height: 80%;
          object-fit: contain;
          // bottom: 4;
        }

        

        
        .nav-button {
  color: #fff;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 50%; /* Ensures a perfect circle */
  text-align: center;
  width: 50px; /* Equal width and height for small devices */
  height: 50px; /* Equal width and height for small devices */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  box-sizing: border-box; /* Ensures padding doesn't affect width/height */
}

@media (min-width: 1024px) {
  .nav-button {
    width: 50px; /* Increase width for XL devices */
    height: 50px; /* Increase height for XL devices */
  }
}

@media (min-width: 768px) {
  .nav-button {
    width: 50px; /* Increase width for XL devices */
    height: 50px; /* Increase height for XL devices */
  }
}

@media (min-width: 390px) {
  .nav-button {
    width: 40px; /* Increase width for XL devices */
    height: 40px; /* Increase height for XL devices */
    border-radius: 50%; /* Ensures perfect circle */
    padding-right:10px;
    padding-left:10px;
  }
}



        .close-button {
          position: absolute;
          top: 30px;
          right: 5px;
          // font-size: 40px;
           border: 1px solid white;
  border-radius: 50%; /* Ensures perfect circle */
          width: 50px; /* Set equal width and height */
  height: 50px; /* Set equal width and height */
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
}
          
        }

        .nav-button {
          z-index: 2;
        }
      `}</style>
    </section>
  );
};

export default GallerySlider1;
