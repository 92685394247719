import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer2 from "components/Footers/Footer2";
import Form from "components/Dropdowns/Form";

export default function Homepage1() {
  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:block md:block sm:block block xl:pb-16 figtree lg:pb-16 pb-12 sofia-pro">
        <div className="container mx-auto xl:px-8 px-4">
          <p className="xl:text-base font-semibold text-nightblack xl:pt-16 text-base lg:pt-12 md:pt-24 pt-24">
            CONTACT
          </p>
          <h1 className="xl:text-4xl text-iceblue figtree xl:pt-2 pt-2 md:text-3xl text-2xl">
            We are committed to providing<br></br>{" "}
            <span className="font-bold">exceptional care and support </span>
          </h1>
          <p className="xl:text-lg xl:pt-4 text-nightblack md:text-lg lg:text-lg text-base pt-4">
            If you have any questions, concerns, or need assistance, <br></br>
            please don't hesitate to reach out to us.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:mt-12 lg:mt-8 mt-8 xl:gap-0 lg:gap-0 md:gap-0 gap-4">
            <div className="">
              <div className="xl:w-8/12 lg:w-8/12 md:w-9/12 right-borderr  ">
                <h1 className="xl:text-2xl text-iceblue font-semibold md:text-2xl lg:text-2xl text-xl ">
                  Request a callback
                </h1>
                <p className="xl:text-lg text-nightblack xl:pt-2 md:text-lg lg:text-lg text-base pt-2">
                  For medical emergencies, please call
                </p>
                <p className="xl:text-lg text-iceblue xl:pt-2 font-bold lg:text-lg md:text-lg text-base pt-2">
                  98800 09596
                </p>
                <div className="border-bottomm xl:mt-4 mt-4"></div>

                <h1 className="xl:text-2xl text-iceblue font-semibold  xl:mt-4 lg:text-2xl md:text-2xl text-xl mt-4 ">
                  Having a query mail us
                </h1>
                <p className="xl:text-lg text-nightblack xl:pt-2 lg:text-lg md:text-lg text-base pt-2">
                  If you have any questions or need further information, feel
                  free to email us
                </p>
                <p className="xl:text-lg text-iceblue xl:pt-2 font-bold lg:text-lg md:text-lg text-base pt-2">
                  info@nurturehospitals.in
                </p>
                <div className="border-bottomm xl:mt-4 mt-4"></div>

                <h1 className="xl:text-2xl text-iceblue font-semibold  xl:mt-4 lg:text-2xl  md:text-2xl text-xl mt-4">
                  Address
                </h1>
                <p className="xl:text-lg text-nightblack xl:pt-2 lg:text-lg md:text-lg text-base pt-2">
                  We are conveniently located to serve your healthcare needs.
                  You can find us at
                </p>
                <p className="xl:text-lg text-iceblue xl:pt-2 font-bold lg:text-lg md:text-lg text-base pt-2">
                  No. 80 (Old No. 36), 20th Main Road, 2nd Block, Rajajinagar,
                  Bangalore - 560010
                </p>
                <div className="border-bottomm xl:mt-4 mt-4"></div>

                <div className="xl:mt-4 mt-4 xl:block lg:block md:block sm:hidden hidden">
                  <p className="xl:text-2xl text-iceblue font-bold">
                    Keep in touch
                  </p>
                  <div className="flex xl:mt-2 mt-2">
                    <button className="icon">
                      <a
                        href="https://facebook.com/people/Nurture-Hospital/100090725858538/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    </button>

                    <button className="icon xl:ml-2 ml-2">
                      <a
                        href="https://wa.me/919880009596" // WhatsApp URL with the phone number
                        target="_blank" // Open in a new tab
                        rel="noopener noreferrer" // For security reasons
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </button>

                    <button className="icon xl:ml-2 ml-2">
                      <a
                        href="https://www.instagram.com/nurture.hospital/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </button>

                    <button className="icon xl:ml-2 ml-2">
                      <a
                        href="https://www.youtube.com/channel/UCCaBg-vsnaamCOsSnSgbsLw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:-ml-20 lg:-ml-20 md:-ml-18">
              <div className="bg-white rounded-md xl:p-12 xl:w-12/12 lg:p-12 lg:w-12/12 md:w-12/12 p-6 shadow-xl">
                <div>
                  <h1 className="xl:text-4xl  text-iceblue figtree text-center md:text-3xl text-2xl">
                    Book an<span className="font-bold"> appointment</span>
                  </h1>
                  <div className="xl:mt-8 lg:mt-8 mt-8">
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </section>
  );
}
