import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ancccare from "assets/img/ANC-Care.png";
import riskpregrancy from "assets/img/High-Risk-Pregnancy.png";
import obestrics from "assets/img/Obstetrics.png";
import postnatalcare from "assets/img/Post-Natal-care.png";
import fetalmedicine from "assets/img/Fetal-Medicine.png";
import ultrasonography from "assets/img/Ultrasonography.png";
import fertility from "assets/img/Fertility.png";
import familyplanning from "assets/img/Family-planning.png";
import adosclenthealth from "assets/img/Adolescent Health.png";
import gynaecology from "assets/img/Gynaecology.png";
import laproscopic from "assets/img/Laproscopic-Surgeries.png";
import womenclinic from "assets/img/well-woman-clinic.png";
import neonatology from "assets/img/Neonatology.png";
import vaccination from "assets/img/Vaccination.png";
import nicu from "assets/img/NICU.png";
import paediatrics from "assets/img/Paediatrics.png";
import dermatalogy from "assets/img/Dermatology.png";
import cosmotology from "assets/img/Cosmetology.png";
import generalsurgery from "assets/img/General-Surgery.png";
import urology from "assets/img/urology.png";

// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const ArrowLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} `}
      style={{
        ...style,
        display: "block",
        left: "10px", // Adjust as needed
        zIndex: 2,
      }}
      onClick={onClick}
    ></div>
  );
};

const ArrowRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} `}
      style={{
        ...style,
        display: "block",
        right: "10px", // Adjust as needed
        zIndex: 2,
      }}
      onClick={onClick}
    ></div>
  );
};

const Galleryslider = () => {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 0 }); // Navigate with state
  };

  const handleClick1 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 16 }); // Navigate with state
  };

  const handleClick2 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 22 }); // Navigate with state
  };

  const handleClick3 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 21 }); // Navigate with state
  };

  const handleClick4 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 13 }); // Navigate with state
  };

  const handleClick5 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 20 }); // Navigate with state
  };

  const handleClick6 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 3 }); // Navigate with state
  };

  const handleClick7 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 19 }); // Navigate with state
  };

  const handleClick8 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 18 }); // Navigate with state
  };

  const handleClick9 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 17 }); // Navigate with state
  };

  const handleClick10 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 12 }); // Navigate with state
  };

  const handleClick11 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 23 }); // Navigate with state
  };

  const handleClick12 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 15 }); // Navigate with state
  };

  const handleClick13 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 24 }); // Navigate with state
  };

  const handleClick14 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 25 }); // Navigate with state
  };

  const handleClick15 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 1 }); // Navigate with state
  };

  const handleClick16 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 2 }); // Navigate with state
  };

  const handleClick17 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 26 }); // Navigate with state
  };

  const handleClick18 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 27 }); // Navigate with state
  };

  const handleClick19 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 28 }); // Navigate with state
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    arrows: true, // Enable arrows
    // prevArrow: <ArrowLeft />, // Use custom previous arrow
    // nextArrow: <ArrowRight />, // Use custom next arrow
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section>
      <div>
        <Slider {...settings}>
          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={ancccare} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick9}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  ANC Care
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={riskpregrancy} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick8}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  RiskPregnancy
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={obestrics} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick7}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Obstetrics
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={postnatalcare} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick6}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  PostNatalcare
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={fetalmedicine} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick5}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  FetalMedicine
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={ultrasonography} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick4}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Ultrasonography
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={fertility} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Fertility
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={familyplanning} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick3}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Familyplanning
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={adosclenthealth} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick2}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover ">
                AdolescentHealth
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={gynaecology} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick1}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Gynaecology
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={laproscopic} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick10}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Laproscopic
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover  lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={womenclinic} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick11}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  womenclinic
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={neonatology} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick12}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Neonatology
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={vaccination} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick13}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Vaccination
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={nicu} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick14}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  NICU
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={paediatrics} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick15}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Paediatrics
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={dermatalogy} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick16}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Dermatology
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={cosmotology} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick17}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Cosmetology
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={generalsurgery} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick18}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  GeneralSurgery
                </p>
              </a>
            </div>
          </div>

          <div className="px-2">
            <div className="rounded-lg bg-6 xl:p-8 bg-hover lg:p-8 md:p-8 p-8 sofia-pro">
              <div className="flex justify-center">
                <div className="h-16 w-16 rounded-full bg-white">
                  <div className="center-button">
                    <img class="w-12 pt-2" src={urology} alt="" />
                  </div>
                </div>
              </div>
              <a href="/Services" onClick={handleClick19}>
                <p className="xl:text-base text-base font-semibold text-nightblack text-center pt-2 text-hover">
                  Urology
                </p>
              </a>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Galleryslider;
