import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const CustomPrevArrow = (props) => {
  return null; // Render nothing
};

const CustomNextArrow = (props) => {
  return null; // Render nothing
};

const Profile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000, // Set the speed for autoplay (in milliseconds)
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section>
      <div>
      <Slider {...settings}>
  <div className="flex justify-center space-x-8 flex-col items-center">
    <h1 className="xl:text-5xl lg:text-3xl text-3xl font-semibold text-white">Awards</h1>
  </div>

  <div className="flex justify-center space-x-8 flex-col items-center">
    <h1 className="xl:text-5xl lg:text-3xl text-3xl font-semibold text-white">Awards</h1>
  </div>
</Slider>

      </div>
    </section>
  );
};

export default Profile;
