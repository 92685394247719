import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import AccordionFooter from "./AccordionFooter";
import nurtureheader1 from "assets/img/nurtureheader1.png";
import phone1 from "assets/img/phoneicon.png";
import wattsupicon from "assets/img/wattsupicon.png";
import calenderwhite from "assets/img/calendar white.png";
import patternsection1 from "assets/img/pattern-section.png";
import happyfamily from "assets/img/happi-family.png";
import happyfamily1 from "assets/img/happy-family.png";
import Login from "views/auth/Login"; // Ensure this import is correct
import nurtureqr from "assets/img/Nurtureqr.png";
import review from "assets/img/review.png";

export default function Footer2() {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const handleClick = (message) => {
    // Replace this alert with any functionality you want on click
    alert(message);
  };

  {
    return (
      <>
        <section className="xl:block lg:block md:block sm:block block xl:pb-10 lg:pb-12 pb-12 md:pb-20">
          <div className="container mx-auto xl:px-4 lg:px-4 md:px-4 px-4">
            <div
              className="bg-new"
              style={{
                backgroundImage: `url(${patternsection1})`,

                backgroundSize: "75%",
                backgroundPosition: "bottom-center",
              }}
            >
              <div className="grid grid-cols-2">
                <div className="flex items-center xl:ml-16 lg:ml-12 md:ml-12 ml-2">
                  <h1 className="text-white xl:text-5xl text-center lg:text-3xl md:text-2xl text-lg sofia-pro">
                    Bringing new life into the &nbsp; world{" "}
                    <span className="font-bold"> with love and care</span>
                  </h1>
                </div>

                <div>
                  <div className="flex justify-end  height">
                    <img
                      class="xl:w-7/12 lg:w-8/12 md:w-8/12 w-12/12 mt-4 xl:mt-0 lg:mt-0 md:mt-2"
                      src={happyfamily1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4">
            <div className="xl:mt-8 flex lg:mt-8 items-center mt-6">
              <div className="xl:w-5/12  lg:w-5/12 md:w-5/12 w-40">
                <hr className="border-blue-50"></hr>
              </div>
              <div className="flex justify-center xl:w-2/12 lg:w-2/12 md:w-40 w-40">
                <div>
                  <img class="" src={nurtureheader1} alt="" />
                </div>
              </div>
              <div className="xl:w-5/12 ml-2 lg:w-5/12 md:w-5/12 w-40">
                <hr className="border-blue-50"></hr>
              </div>
            </div>

            <div className="xl:mt-6 lg:mt-4 mt-4">
              <div className="flex justify-center">
                <button className="icon">
                  <a
                    href="https://facebook.com/people/Nurture-Hospital/100090725858538/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </button>

                <button className="icon xl:ml-4 lg:ml-4 ml-4">
                  <a
                    href="https://wa.me/919880009596" // WhatsApp URL with the phone number
                    target="_blank" // Open in a new tab
                    rel="noopener noreferrer" // For security reasons
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </button>

                <button className="icon xl:ml-4 lg:ml-4 ml-4">
                  <a
                    href="https://www.instagram.com/nurture.hospital/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </button>

                <button className="icon xl:ml-4 lg:ml-4 ml-4">
                  <a
                    href="https://www.youtube.com/channel/UCCaBg-vsnaamCOsSnSgbsLw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </button>
              </div>
            </div>

            <h6 className="xl:text-base text-nightblack text-center xl:mt-5 font-semibold lg:text-base mt-4 text-base sofia-pro">
              © 2024 NurtureHospitals All rights reserved.
            </h6>
            <p className="xl:text-smm  text-center pt-2 text-nightblack lg:text-sm text-sm sofia-pro">
              The content and images used on this site are copyright protected
              and copyrights vests with the respective owners. The usage of the
              content and images on this website is intended to promote the
              works and no endorsement of the artist shall be implied.
              Unauthorized use is prohibited and punishable by law.
            </p>
            <p className="xl:text-smm  text-center pt-2 text-grey-600 lg:text-sm text-sm sofia-pro">
              Candle light dinner | Yoga center | Food delivery from the canteen
              | Baby Photoshoot| Nursing care | Drone shots.
            </p>
          </div>
        </section>

        {/*floationg icons*/}
        <section
          className=" xl:block lg:block md:block sm:hidden hidden fixed right-0 bottom-0 xl:mb-0 lg:mb-0 md:mb-12 mb-4 xl:mr-4  lg:mr-4 md:mr-4 mr-0 xl:p-8 lg:p-4 p-4 "
          style={{ zIndex: 999 }}
        >
          <div className="flex justify-between">
            {/* <div className="fixed left-0 bottom-0 container xl:ml-12 xl:pb-8 lg:ml-8 lg:pb-4 md:ml-8 md:pb-12">
              <div>
                <img className="w-28" src={review} alt="" />
              </div>
            </div> */}

            <div>
              <a
                href="tel:080-41226969"
                className="bg-gradient rounded-md xl:px-4 xl:py-2 px-4 py-2 flex xl:mt-4 cursor-pointer mt-4"
              >
                <div>
                  <img className="w-5 pt-1" src={phone1} alt="Phone Icon" />
                </div>
                <p className="text-white xl:text-lg ml-2 text-lg">
                  080-41226969
                </p>
              </a>

              <div>
                <a
                  href="https://wa.me/919880009596" // WhatsApp URL with the phone number
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // For security reasons
                  className="bg-gradient rounded-md xl:px-4 xl:py-2 px-4 py-2 mt-2 flex xl:mt-2 cursor-pointer"
                >
                  <div className="">
                    <img
                      className="w-5 pt-1"
                      src={wattsupicon}
                      alt="WhatsApp Icon"
                    />
                  </div>

                  <p className="text-white xl:text-lg ml-2">WhatsApp</p>
                </a>
              </div>

              <div
                className="bg-newshade32 rounded-md xl:px-4 xl:py-2 px-4 py-2 mt-2  flex  xl:mt-2 cursor-pointer"
                onClick={openPopup} // Open the popup on phone icon click
              >
                <div>
                  <img
                    className="w-5 pt-1"
                    src={calenderwhite}
                    alt="Phone Icon"
                  />
                </div>
                <p className="text-white xl:text-lg ml-2"> Appointment</p>
              </div>
            </div>
          </div>
        </section>

        {/*floating icons mobile*/}
        <section className="xl:hidden lg:hidden md:hidden sm:block block fixed right-0 bottom-0 mr-4 mb-4">
          <div
            className="bg-gradient h-10 w-10 rounded-full cursor-pointer"
            onClick={() => window.open("https://wa.me/919880009596", "_blank")} // WhatsApp chat on click
          >
            <div className="flex justify-center">
              <img className="w-6 pt-2" src={wattsupicon} alt="WhatsApp Icon" />
            </div>
          </div>

          <div
            className="bg-gradient h-10 w-10 rounded-full mt-2 cursor-pointer"
            onClick={() => (window.location.href = "tel:08041226969")} // Redirect to phone call
          >
            <div className="flex justify-center">
              <img className="w-6 pt-2" src={phone1} alt="Phone Icon" />
            </div>
          </div>

          <div
            className="bg-newshade32 h-10 w-10 rounded-full mt-2"
            onClick={openPopup} // Open the popup on phone icon click
          >
            <div className="flex justify-center">
              <img className="w-6 pt-2 " src={calenderwhite} alt="" />
            </div>
          </div>
        </section>
        <div>{showPopup && <Login closePopup={closePopup} />}</div>
      </>
    );
  }
}
